<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="box welcome-container">
          <div class="columns is-vcentered">
            <div class="column is-narrow ml-1 mt-1">
              <div
                class="profilePicture"
                :style="profilePictureStyleClass"
              ></div>
            </div>
            <div class="column  mb-4">
              <p class="text-heading bold">Welcome back</p>
              <p
                v-if="userDetail"
                class="text-heading"
                style="line-height: 28px"
              >
                {{ userDetail.firstName + " " + userDetail.lastName }}
              </p>
            </div>
            <div class="column is-narrow has-text-centered">
              <img src="@/assets/logo_white.png" width="96px" />
              <p v-if="userDetail" class="bold">
                <span class="tag">{{ "LIBRARY ADMIN" | allCaps }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dashboardOverview">
      <p class="text-heading bold p-2">Overview</p>
      <div class="columns is-multiline">
        <div class="column is-4">
          <router-link :to="{ name: 'Users' }">
            <IconItemCard
              title="Users"
              :value="dashboardOverview.noOfUsers.toString()"
              icon="account-multiple"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'ActiveIssuances' }">
            <IconItemCard
              title="Active Issuances"
              :value="dashboardOverview.activeIssuances.toString()"
              icon="book-plus-multiple"
            />
          </router-link>
        </div>
        <!-- <div class="column is-4">
          <router-link :to="{name: 'XXX'}">
<IconItemCard title="Revenue" value="0 PKR" icon="bank" />
</router-link>

        </div> -->
      </div>
      <hr />
      <p class="text-heading bold p-2">Content</p>
      <div class="columns is-multiline">
        <div class="column is-4">
          <router-link :to="{ name: 'Books' }">
            <IconItemCard
              title="Books"
              :value="dashboardOverview.noOfPrintedBooks.toString()"
              icon="book-open-page-variant"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'EBooks' }">
            <IconItemCard
              title="E-Books"
              :value="dashboardOverview.noOfeBooks.toString()"
              icon="book-open-page-variant"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'Journals' }">
            <IconItemCard
              title="Journals"
              :value="dashboardOverview.noOfJournals.toString()"
              icon="notebook"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'EJournals' }">
            <IconItemCard
              title="E-Journals"
              :value="dashboardOverview.noOfeJournals.toString()"
              icon="notebook"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'Research' }">
            <IconItemCard
              title="Research Articles"
              :value="dashboardOverview.noOfResearches.toString()"
              icon="file-document-multiple-outline"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'EResearch' }">
            <IconItemCard
              title="E-Research Articles"
              :value="dashboardOverview.noOfeResearches.toString()"
              icon="file-document-multiple-outline"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link :to="{ name: 'ENewspaper' }">
            <IconItemCard
              title="E-Newspaper Articles"
              :value="dashboardOverview.noOfeNewspapers.toString()"
              icon="newspaper-variant-outline"
            />
          </router-link>
        </div>
      </div>
      <hr />
      <p class="text-heading bold p-2">Digital Content</p>
      <div class="columns is-multiline">
        <div class="column is-4">
          <router-link
            :to="{ name: 'DigitalMedia', query: { contentType: 'video' } }"
          >
            <IconItemCard
              title="Videos"
              :value="dashboardOverview.noOfVideos.toString()"
              icon="play"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link
            :to="{ name: 'DigitalMedia', query: { contentType: 'image' } }"
          >
            <IconItemCard
              title="Images"
              :value="dashboardOverview.noOfImages.toString()"
              icon="image-area"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link
            :to="{ name: 'DigitalMedia', query: { contentType: 'audio' } }"
          >
            <IconItemCard
              title="Audios"
              :value="dashboardOverview.noOfAudios.toString()"
              icon="music"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link
            :to="{ name: 'DigitalMedia', query: { contentType: 'map' } }"
          >
            <IconItemCard
              title="Maps"
              :value="dashboardOverview.noOfMaps.toString()"
              icon="map"
            />
          </router-link>
        </div>
        <div class="column is-4">
          <router-link
            :to="{ name: 'DigitalMedia', query: { contentType: 'dataset' } }"
          >
            <IconItemCard
              title="Datasets"
              :value="dashboardOverview.noOfDatasets.toString()"
              icon="file"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="loader-container">
      <section class="columns m-2">
        <div class="column is-narrow">
          <p class="text-heading bold">
            Loading Overview...
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import IconItemCard from "@/components/IconItemCard.vue";

export default {
  name: "Dashboard",
  components: { IconItemCard },
  data() {
    return {};
  },
  computed: {
    userDetail() {
      return this.$store.state.userDetail;
    },
    dashboardOverview() {
      return this.$store.state.dashboardOverview;
    },
    hasDashboardOverview() {
      return this.$store.state.dashboardOverview !== undefined;
    },
    profilePictureStyleClass() {
      return this.$store.state.userDetail
        ? "background-image: url(" +
            this.$store.state.userDetail.profilePicture +
            ")"
        : "";
    }
  },
  async created() {
    await this.$store.dispatch("getUserDetail");
    await this.$store.dispatch("getDashboardOverview");
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.loader-container {
  height: 40vh;
}
.profilePicture {
  border: 6px solid $white;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-origin: border-box;
}
</style>
